<template>
  <b-container
    fluid
  >
    <div class="d-flex justify-content-end mb-0 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-container
      fluid
    >
      <b-row>

        <b-col
          cols="12"
          md="8"
          class="px-1"
          order="2"
          order-md="1"
        >
          <b-form-group
            label="Search"
            label-for="filter_item_center_search"
            label-class="font-weight-bolder"
          >
            <b-input
              id="filter_item_center_search"
              v-model="tableItemCenter.filter.search"
              type="text"
              size="sm"
              placeholder="search here"
              debounce="1000"
              autocomplete="off"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="6"
          md="2"
          class="px-1"
          order="1"
          order-md="2"
        >
          <b-form-group
            label="Fiscal Year"
            label-for="filter_fiscal_year"
            label-class="font-weight-bolder"
          >
            <b-select
              id="filter_fiscal_year"
              v-model="tableItemCenter.filter.fiscal_year"
              size="sm"
              :options="filterFiscalYears"
              :disable="state.busy || fetching.options"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="6"
          md="2"
          class="px-1"
          order="1"
          order-md="2"
        >
          <b-form-group
            label="Budget Center"
            label-for="filter_budget_center"
            label-class="font-weight-bolder"
          >
            <b-select
              id="filter_budget_center"
              v-model="tableItemCenter.filter.budget_center"
              size="sm"
              value-field="id"
              text-field="budget_name"
              :options="filterBudgetCenters"
              :disable="state.busy || fetching.options"
            />
          </b-form-group>
        </b-col>

        <b-col
          class="px-1 mb-2"
          order="3"
        >
          <b-button
            type="button"
            size="sm"
            variant="success"
            @click="onCreateItemCenter"
          >
            Add Item Center
          </b-button>
          <b-overlay
            v-if="String(tableItemCenter.filter.fiscal_year) !== 'All'"
            rounded
            opacity="0.1"
            spinner-small
            class="d-inline"
            spinner-variant="warning"
            :show="exporter.busy"
          >
            <json-excel
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="btn btn-success btn-sm ml-1"
              :fetch="excelProvider"
              :before-generate="onStartExcelExport"
              :before-finish="onFinishExcelExport"
              :fields="exporter.excel.fields"
              :escape-csv="exporter.excel.escape"
              :type="exporter.excel.type"
              :header="exporter.excel.header"
              :footer="exporter.excel.footer"
              :name="excelFileName"
              worksheet="Item Centers"
            >
              {{ exporter.busy ? 'Exporting' : 'Export As Excel' }}
            </json-excel>
          </b-overlay>
        </b-col>
      </b-row>
      <b-row
        class="mt-1"
      >
        <b-col
          cols="12"
          class="px-1"
        >
          <b-table
            ref="tableItemCenter"
            hover
            small
            striped
            bordered
            responsive
            show-empty
            :stacked="isMobile()"
            :per-page="tableItemCenter.perPage"
            :current-page="tableItemCenter.currentPage"
            :items="tableItemCenterProvider"
            :fields="tableItemCenter.fields"
            :sort-by.sync="tableItemCenter.sortBy"
            :sort-desc.sync="tableItemCenter.sortDesc"
            :sort-direction="tableItemCenter.sortDirection"
            :filter="tableItemCenter.filter"
            :filter-included-fields="tableItemCenter.filterOn"
            :busy="tableItemCenter.busy"
          >
            <template #cell(index)="row">
              {{ tableItemCenter.currentPage * tableItemCenter.perPage - tableItemCenter.perPage + (row.index + 1) }}
            </template>

            <template #cell(active)="row">
              {{ Number(row.value) === 1 ? 'Yes' : 'No' }}
            </template>

            <template #cell(budget)="row">
              <div
                class="text-md-nowrap text-right"
                style="min-width: 120px;"
              >
                <b-button
                  size="sm"
                  variant="link"
                  class="text-success font-weight-bolder"
                  :disable="state.busy"
                  @click="onShowBudgets(row.item, 0)"
                >
                  {{ row.value }}
                </b-button>
              </div>
            </template>

            <template #cell(total_pending)="row">
              <div
                class="text-md-nowrap text-right"
                style="min-width: 120px;"
              >
                <b-button
                  size="sm"
                  variant="link"
                  class="text-secondary font-weight-bolder"
                  :disable="state.busy"
                  @click="onShowBudgets(row.item, 1, 'Pending')"
                >
                  {{ row.value }}
                </b-button>
              </div>
            </template>

            <template #cell(total_spent)="row">
              <div
                class="text-md-nowrap text-right"
                style="min-width: 120px;"
              >
                <b-button
                  size="sm"
                  variant="link"
                  class="text-danger font-weight-bolder"
                  :disable="state.busy"
                  @click="onShowBudgets(row.item, 1, 'Released')"
                >
                  {{ row.value }}
                </b-button>
              </div>
            </template>

            <template #cell(balance)="row">
              <div
                class="font-weight-bolder text-md-nowrap text-right"
                style="min-width: 120px;"
              >
                {{ row.value }}
              </div>
            </template>

            <template #cell(percentage)="row">
              <div
                class="text-md-nowrap text-right"
                style="min-width: 100px;"
              >
                {{ row.value }}
              </div>
            </template>

            <template #cell()="row">
              <div class="text-md-nowrap">
                {{ row.value }}
              </div>
            </template>

            <template #cell(action)="row">
              <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-center">
                <b-button
                  size="sm"
                  :variant="Number(row.item.total_spent) > 0 ? 'danger' : 'success'"
                  :disabled="Number(row.item.total_spent) > 0"
                  @click="onEditItemCenter(row.item)"
                >
                  Edit
                </b-button>
              </div>
            </template>

            <template #table-busy>
              <div class="text-center py-5">
                <b-icon
                  icon="stopwatch"
                  font-scale="5"
                  animation="cylon"
                />
                <p class="h3 py-2">
                  <strong>Loading . . .</strong>
                </p>
              </div>
            </template>

          </b-table>

        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mt-1 px-1"
        >
          <b-select
            v-model="tableItemCenter.perPage"
            :options="tableItemCenter.pageOptions"
            size="sm"
            class="w-100 w-md-25"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
        >
          <b-pagination
            v-model="tableItemCenter.currentPage"
            pills
            last-number
            first-number
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
            :total-rows="tableItemCenter.totalRows"
            :per-page="tableItemCenter.perPage"
          />
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      id="modal-item-center"
      size="md"
      scrollable
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      hide-header-close
      :title="ModalItemCenterTitle"
    >
      <ValidationObserver
        ref="formItemCenter"
      >
        <form
          role="form"
          novalidate
          @submit.prevent
        >
          <b-row>
            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="fiscal year"
                vid="fiscal_year"
                rules="required"
              >
                <b-form-group
                  label="Fiscal Year"
                  label-for="fiscal_year"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-select
                    id="fiscal_year"
                    v-model="itemCenter.fiscal_year"
                    :options="lists.fiscalYears"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  >
                    <template #first>
                      <b-select-option
                        :value="null"
                        disabled
                      >
                        -- select fiscal year here --
                      </b-select-option>
                    </template>
                  </b-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="budget center"
                vid="budget_center"
              >
                <b-form-group
                  label="Budget Center"
                  label-for="budget_center"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <v-select
                    v-model="selected.budgetCenter"
                    input-id="budget_center"
                    type="text"
                    append-to-body
                    label="budget_name"
                    placeholder="search budget center here"
                    :calculate-position="calculateDropPosition"
                    :options="lists.budgetCenters"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                    :disabled="(fetching.options || state.busy || state.editing)"
                    :loading="fetching.options"
                  >
                    <template #no-options="">
                      no available budget center
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >

              <ValidationProvider
                #default="{ errors }"
                name="item"
                vid="item"
              >
                <b-form-group
                  label="Item"
                  label-for="item"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <v-select
                    v-model="selected.item"
                    input-id="item"
                    type="text"
                    append-to-body
                    label="item_name"
                    placeholder="search item here"
                    :calculate-position="calculateDropPosition"
                    :options="lists.items"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                    :disabled="(fetching.items || state.busy || state.editing)"
                    :loading="fetching.items"
                  >
                    <template #option="{ item_name }">
                      <span class="text-wrap">{{ item_name }}</span>
                    </template>
                    <template #no-options="">
                      no available item
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col
              v-if="state.editing"
              cols="12"
            >
              <b-form-group>
                <b-form-checkbox
                  id="is_active"
                  v-model="itemCenter.active"
                  name="is_active"
                  unchecked-value="0"
                  value="1"
                  switch
                  :disabled="state.busy"
                >
                  Is Active?
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="onValidateItemCenter"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>

    <b-modal
      id="modal-budgets"
      title="Budgets"
      size="xl"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      modal-class="fullscreen"
    >
      <b-tabs
        v-model="state.currentTabIndex"
        active-nav-item-class="font-weight-bold text-uppercase text-success"
        content-class="scrollable"
      >
        <b-tab class="py-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-exchange-dollar-line h4" />
              <b class="is-tab-active">Budgets</b>
            </div>
          </template>
          <b-row>
            <b-col
              cols="12"
            >
              <ItemCenterBudget
                :current-tab-index="state.currentTabIndex"
                :item-center="itemCenter"
              />
            </b-col>
          </b-row>
        </b-tab>
        <b-tab class="py-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-archive-line h4" />
              <b class="is-tab-active">Transactions</b>
            </div>
          </template>
          <b-row>
            <b-col
              cols="12"
            >
              <ItemCenterTransactions
                :current-tab-index="state.currentTabIndex"
                :item-center="itemCenter"
                :status="state.status"
              />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import QUERY from 'lodash'
import { core } from '@/config/pluginInit'
import { BOItemCenterService, SharedListService } from '@/services'
import ItemCenterBudget from '@/views/bo/item-centers/Budgets'
import ItemCenterTransactions from '@/views/bo/item-centers/Transactions'
import formatter from '@/mixins/formatter'
import Ripple from 'vue-ripple-directive'
import misc from '@/mixins/misc'

export default {

  name: 'BoMaintenancesItemCenters',

  middleware: ['auth', 'bo'],

  directives: {
    Ripple
  },

  components: {
    ItemCenterBudget,
    ItemCenterTransactions
  },

  mixins: [formatter, misc],

  metaInfo: () => ({
    title: 'Item Centers'
  }),

  data () {
    return {
      state: {
        busy: false,
        editing: false,
        currentTabIndex: 0,
        status: 'Pending'
      },
      fetching: {
        options: false,
        items: false
      },
      lists: {
        budgetCenters: [],
        fiscalYears: [],
        items: []
      },
      selected: {
        budgetCenter: null,
        itemCenter: null,
        item: null
      },
      itemCenter: {
        id: null,
        fiscal_year: null,
        fiscal_name: null,
        budget_center: null,
        budget_name: null,
        item: null,
        item_name: null,
        active: 1
      },
      tableItemCenter: {
        perPage: 10,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: null,
          fiscal_year: 'All',
          budget_center: 'All'
        },
        filterOn: [],
        busy: false,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 11, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'created_at', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'fiscal_year.fiscal_name', label: 'Fiscal Year', class: 'text-center' },
          { mobile: 3, key: 'budget_center.budget_name', label: 'Budget Center' },
          { mobile: 4, key: 'item.item_name', label: 'Item' },
          {
            mobile: 5,
            key: 'budget',
            formatter: (_val, _key, itemCenter) => this.numberFormatter(
              this.getBudget(itemCenter)
            ) || parseFloat(0.00).toFixed(2)
          },
          { mobile: 5, key: 'total_pending', label: 'Pending', formatter: this.numberFormatter },
          { mobile: 5, key: 'total_spent', label: 'Spent', formatter: this.numberFormatter },
          {
            mobile: 6,
            key: 'balance',
            formatter: (_val, _key, itemCenter) => this.numberFormatter(
              this.getBalance(itemCenter)
            ) || parseFloat(0.00).toFixed(2)
          },
          {
            mobile: 7,
            key: 'percentage',
            label: '%',
            formatter: (_val, _key, itemCenter) => this.numberFormatter(
              this.getBalancePercentage(itemCenter)
            ) || parseFloat(0.00).toFixed(2)
          },
          { mobile: 9, key: 'active', class: 'text-center' },
          { mobile: 10, key: 'updated_at', formatter: this.dateTimeShortFormatter, sortable: true }
        ].sort(this.scaleOnMobile)
      },
      exporter: {
        busy: false,
        pdf: null,
        excel: {
          header: [],
          escape: false,
          type: 'xls',
          fields: {
            'Fiscal Year': {
              field: 'fiscal_year.fiscal_name'
            },
            'Budget Center': {
              field: 'budget_center.budget_name'
            },
            Item: {
              field: 'item.item_name'
            },
            Budget: {
              field: '',
              callback: itemCenter => this.numberFormatter(
                this.getBudget(itemCenter)
              ) || 0.00
            },
            Pending: {
              field: 'total_pending',
              callback: amount => this.numberFormatter(amount)
            },
            Spent: {
              field: 'total_spent',
              callback: amount => this.numberFormatter(amount)
            },
            Balance: {
              field: '',
              callback: itemCenter => this.numberFormatter(
                this.getBalance(itemCenter)
              ) || 0.00
            },
            Percentage: {
              field: '',
              callback: itemCenter => this.numberFormatter(
                this.getBalancePercentage(itemCenter)
              ) || 0.00
            }
          },
          footer: []
        }
      }
    }
  },

  computed: {
    ModalItemCenterTitle () {
      return this.state.editing ? 'Edit Item Center' : 'Add Item Center'
    },

    filterFiscalYears () {
      return [{ text: 'All', value: 'All' }].concat(this.lists.fiscalYears)
    },

    filterBudgetCenters () {
      return [{ id: 'All', budget_name: 'All' }].concat(this.lists.budgetCenters)
    },

    excelFileName () {
      return `Revolving Funds ${this.tableItemCenter.filter.fiscal_year} - ${this.tableItemCenter.filter.budget_center}`
    }
  },

  watch: {

    'selected.budgetCenter' (budgetCenter) {
      this.itemCenter.budget_center = budgetCenter?.id || null
    },

    'selected.item' (item) {
      this.itemCenter.item = item?.id || null
    },

    'itemCenter.budget_center' (value) {
      this.getItems(value).then(() => {
        if (this.selected.itemCenter) {
          this.selected.item = QUERY.find(this.lists.items, {
            id: this.selected.itemCenter?.item_id
          })
        }
      })
    }

  },

  mounted () {
    core.index()
    this.getOptions()
  },

  methods: {

    async tableItemCenterProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableItemCenter.busy = true
      return await BOItemCenterService.get(
        this.objectToUrl({
          page: currentPage,
          per_page: perPage,
          sort: sortBy,
          sort_desc: sortDesc,
          filter_text: filter.search,
          budget_center: filter.budget_center,
          fiscal_year: filter.fiscal_year
        })
      ).then(({ data }) => {
        this.tableItemCenter.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableItemCenter.busy = false
      })
    },

    tableItemCenterRefresh () {
      this.$refs.tableItemCenter.refresh()
    },

    async excelProvider () {
      return await BOItemCenterService.export(
        this.objectToUrl({
          page: this.tableItemCenter.currentPage,
          per_page: this.tableItemCenter.perPage,
          sort: this.tableItemCenter.sortBy,
          sort_desc: this.tableItemCenter.sortDesc,
          filter_text: this.tableItemCenter.filter.search,
          budget_center: this.tableItemCenter.filter.budget_center,
          fiscal_year: this.tableItemCenter.filter.fiscal_year
        })
      ).then(({ data }) => {
        return data.items
      }).finally(() => {
        this.exporter.busy = false
      })
    },

    onStartExcelExport () {
      this.exporter.busy = true
    },

    onFinishExcelExport () {
    },

    async getOptions () {
      this.state.busy = this.fetching.options = true
      return new Promise(resolve => {
        SharedListService.getCustomList(
          this.toArrayListParam([
            'budget_centers',
            'fiscal_years'
          ])
        ).then(
          ({ data }) => {
            this.lists.budgetCenters = data.budget_centers
            this.lists.fiscalYears = data.fiscal_years.map(
              item => ({ text: item.fiscal_name, value: item.id })
            )
            resolve(data)
          }
        )
      }).finally(() => {
        this.state.busy = this.fetching.options = false
      })
    },

    async getItems (budgetCenter) {
      this.state.busy = this.fetching.items = true
      this.selected.item = null
      this.lists.items = []
      return new Promise(resolve => {
        SharedListService.getItems(
            `budget_center=${budgetCenter}`
        ).then(
          ({ data }) => {
            this.lists.items = data
            resolve(data)
          }
        )
      }).finally(() => {
        this.state.busy = this.fetching.items = false
      })
    },

    onCreateItemCenter () {
      this.state.editing = false
      this.itemCenter.id = 0
      this.itemCenter.fiscal_year = null
      this.itemCenter.active = 1

      this.selected.itemCenter = null
      this.selected.budgetCenter = null
      this.selected.item = null

      this.$bvModal.show('modal-item-center')
    },

    onEditItemCenter (itemCenter) {
      this.state.editing = true

      this.itemCenter.id = itemCenter.id
      this.itemCenter.fiscal_year = itemCenter.fiscal_year_id
      this.itemCenter.active = itemCenter.active

      this.selected.itemCenter = itemCenter
      this.selected.budgetCenter = QUERY.find(this.lists.budgetCenters, { id: itemCenter.budget_center_id })

      this.$bvModal.show('modal-item-center')
    },

    onShowBudgets (itemCenter, currentTabIndex = 0, status = 'Pending') {
      this.state.currentTabIndex = currentTabIndex
      this.state.status = status
      this.itemCenter.id = itemCenter.id
      this.itemCenter.fiscal_year = itemCenter.fiscal_year_id
      this.itemCenter.fiscal_name = itemCenter.fiscal_year.fiscal_name
      this.itemCenter.budget_center = itemCenter.budget_center_id
      this.itemCenter.budget_name = itemCenter.budget_center.budget_name
      this.itemCenter.item = itemCenter.item_id
      this.itemCenter.item_name = itemCenter.item.item_name
      this.itemCenter.active = itemCenter.active
      this.$bvModal.show('modal-budgets')
    },

    async onValidateItemCenter (event) {
      event.preventDefault()
      await this.$refs.formItemCenter.validate().then(
        async allFieldsAreValid => {
          if (allFieldsAreValid) {
            this.swalConfirm({
              html: () => {
                if (this.state.editing) {
                  return '<h6>Save your Changes on this Item Center?</h6>'
                }
                return '<h6>Create this Item Center?</h6>'
              },
              preConfirm: () => {
                this.state.busy = true
                if (this.state.editing) {
                  return this.onPutItemCenter()
                }
                return this.onPostItemCenter()
              }
            })
          } else {
            this.swalInvalid()
          }
        }
      )
    },

    async onPostItemCenter () {
      return new Promise(resolve => {
        BOItemCenterService.post(this.itemCenter).then(
          ({ data: { message } }) => {
            this.tableItemCenterRefresh()
            this.swalSuccess(message).then(() => {
              this.$bvModal.hide('modal-item-center')
            })
            resolve(message)
          }
        ).catch(
          ({ message }) => {
            this.$refs.formItemCenter.setErrors(message)
            resolve(message)
          }
        ).finally(() => {
          this.state.busy = false
        })
      })
    },

    async onPutItemCenter () {
      return new Promise(resolve => {
        BOItemCenterService.put(this.itemCenter).then(
          ({ data: { item, message } }) => {
            const tableRowItemCenter = QUERY.find(this.$refs.tableItemCenter.localItems, { id: item.id })
            if (tableRowItemCenter) {
              tableRowItemCenter.active = item.active
              tableRowItemCenter.fiscal_year_id = item.fiscal_year_id
              tableRowItemCenter.fiscal_year = item.fiscal_year
              tableRowItemCenter.budget_center_id = item.budget_center_id
              tableRowItemCenter.budget_center = item.budget_center
              tableRowItemCenter.item_id = item.item_id
              tableRowItemCenter.item = item.item
              tableRowItemCenter.updated_at = item.updated_at
            }
            this.swalSuccess(message).then(() => {
              this.$bvModal.hide('modal-item-center')
            })
            resolve(message)
          }
        ).catch(
          ({ message }) => {
            this.$refs.formItemCenter.setErrors(message)
            resolve(message)
          }
        ).finally(() => {
          this.state.busy = false
        })
      })
    }

  }
}
</script>
